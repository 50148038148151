<div class="container-body">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" routerLinkActive="active"
        routerLink="/equity" [queryParams]= "{selectedTab:1}">PORTFOLIO LIBRARY</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{portfolioName}}</li>
    </ol>
  </nav>
  
  <div class="centered-content">
    <div class="row">
    <div class="col-md-6 mb-4">
       <span class="label">Name:</span> <span class="value">{{portfolioObj != undefined ? portfolioObj.portfolioName:""}}</span>
    </div>
    <div class="col-md-6 mb-4">
       <span class="label">Type:</span> <span class="value">{{portfolioObj != undefined ? portfolioObj.portfolioType:""}}</span>
    </div>   
    <div class="col-md-6 mb-4">
       <span class="label">Category:</span> <span class="value">{{portfolioObj != undefined ? portfolioObj.category:""}}</span>
    </div>    
    <div class="col-md-6 mb-4">
      <span class="label">Description:</span> <span class="value">{{portfolioObj != undefined ? portfolioObj.description:""}}</span>
   </div>
   <div class="col-md-6 mb-4">
    <span>
      <button mat-button color="primary" *ngIf="portfolioObj != undefined"
      [cdkCopyToClipboard]="buildShareLink(portfolioObj.id,portfolioObj.portfolioName)"
      matTooltip="Copy sharable link to clipboard"><mat-icon>share</mat-icon>LINK TO SHARE</button>
  </span>
  </div>
    <div class="col-md-6 mb-4">
       <mat-dialog-actions>
          <button mat-button color="primary" *ngIf="freeSubscriptionAllowed"
             (click)="subscriberPortfolio('Subscription')"><mat-icon>alarm_add</mat-icon>GET EMAIL UPDATES
          </button> 
          <button mat-button color="primary" *ngIf="cancelSubscriptionAllowed"
             (click)="unSubscribePortfolio('UnSubscribe')"><mat-icon>alarm_off</mat-icon>STOP EMAIL UPDATES
          </button>       
          <button mat-button color="primary" *ngIf="renewSubscriptionAllowed"
             (click)="renewSubscribePortfolio('Renew')"><mat-icon>alarm_add</mat-icon>RENEW EMAIL UPDATES
          </button>
          <button mat-button color="primary" *ngIf="paidSubscriptionAllowed"
             (click)="raisePortfolioRequest('Raise Request')">	<mat-icon>pan_tool</mat-icon>RAISE REQUEST
          </button>
          <button mat-button color="primary" *ngIf="requestApprovalPending">APPROVAL PENDING
          </button>
       </mat-dialog-actions>
    </div>    
    </div>
 </div>
 
    <div class="action-buttons" *ngIf="portfolioOwnerSame">
        <!-- Add Stock Button -->
        <button mat-menu-item color="primary"  
          (click)="openAddStockDialog()">
          <mat-icon>playlist_add</mat-icon>ADD SCRIP
      </button>                      
        <button mat-menu-item color="primary" *ngIf="portfolioObj.portfolioType == 'pro'"
            (click)="portfolioSubscriptionDialogOpen('Portfolio Subscription')">
          <mat-icon>verified_user</mat-icon>PORTFOLIO PERMISSIONS
        </button> 
        <button mat-menu-item color="primary"
          (click)="renamePortfolioDialogOpen('rename', 'Update Portfolio')">
          <mat-icon>settings</mat-icon>UPDATE PORTFOLIO
        </button>
        <button mat-menu-item color="warn"
        (click)="deletePortfolio()">
          <mat-icon>delete_sweep</mat-icon>DELETE PORTFOLIO
        </button>     
    </div>
<!-- show gap if logged in user not portfolio owner -->
    <div *ngIf="!portfolioOwnerSame">&nbsp;</div> 
    <div class="portfolio-container">
      <button matTooltip="Refresh" color="primary" mat-button (click)="getPublicPortfolioItems()">
        <mat-icon>refresh</mat-icon>
      </button>
    <div class="search-container">
    
    <mat-form-field class="search-input" appearance="outline">
        <mat-label>
            <mat-icon>search</mat-icon>
        </mat-label>            
        <input matInput placeholder="Search with any keyword" (keyup)="applyEquityOrderFilter($event)" #inputViewPort>
    </mat-form-field>  
    </div>   <!-- close search -->
    <div class="table-wrapper">    
          <mat-table [dataSource] = "dataSource" matSort  #portfolioViewSort="matSort" >             
            <ng-container matColumnDef="tradingsymbol">
              <mat-header-cell class="sticky-column" *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tradingsymbol"> Name </mat-header-cell>
              <mat-cell *matCellDef="let element" class="sticky-column"> {{element.tradingsymbol}} </mat-cell>
            </ng-container>           
          
              <ng-container matColumnDef="transactionType">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by transactionType" > Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.transactionType}} </mat-cell>
              </ng-container>
             
              <ng-container matColumnDef="triggerPrice">
                  <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by triggerPrice" > Reco Price </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.triggerPrice}} </mat-cell>
                </ng-container>                          
                <ng-container matColumnDef="exitPrice">
                  <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by exitPrice" > Exit Price </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.exitPrice}} </mat-cell>
                </ng-container>
               
                <ng-container matColumnDef="lastPrice">
                  <mat-header-cell *matHeaderCellDef sortActionDescription="Sort by lastPrice" > Last Price </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.strikeData.last_price}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="returnPercent">
                  <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by returnPercent" > Return % </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.returnPercent}} % </mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdDate"> Reco Date </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.createdDate}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="exitDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by exitDate" > Exit Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.exitDate}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderStatus">
                  <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by orderStatus" > OrderStatus </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.orderStatus}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="action-buttons">
                    <button mat-raised-button [disabled]="element.orderStatus == 'CLOSE'"
                      (click)="openExitRecoDialog(element, 'MODIFY')">
                      <mat-icon>create</mat-icon></button>
                    <!-- <button mat-button [disabled]="element.orderStatus == 'CLOSE'"
                      (click)="removeEquityOrder(element._id,element.tradingsymbol,'Delete')"
                      matTooltip="delete scrip">
                      <mat-icon color="warn">delete</mat-icon>
                    </button> -->
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
                <mat-row *matRowDef="let row; columns: getDisplayedColumns();"></mat-row>
          </mat-table>
      </div>            <!-- close table-wrapper -->
      <div class="paginator-container">
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" #portfolioViewPaginator
      showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
    </div>
      </div> <!-- close portfolio-containter -->
      
      <div class="table-wrapper">
        <h3>Frequency of scrip entry, profit/loss booking</h3>
        <mat-form-field appearance="outline" class="form-element">
          <mat-label>Select Period</mat-label>
          <mat-select name="privatePortSel" [(ngModel)]="periodSelItem" class="form-element"  (selectionChange)="ondurationSelect()">
            <mat-option value="3">last 3 months</mat-option>
            <mat-option value="6" >last 6 months</mat-option>
            <mat-option value="12">last 12 months</mat-option>
          </mat-select>
        </mat-form-field>
        <canvas  id="MyChart">{{ chart }}</canvas>
    </div>
  </div>
